import React, { useState, useEffect } from 'react';
import { ref, onValue, update } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { auth, database, storage } from '../../firebaseConfig';
import { User, Save, Edit2, Upload } from 'lucide-react';
import Sidebar from './Sidebar';

const Tprofile = () => {
  const [profile, setProfile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const userRef = ref(database, `users/${userId}`);
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setProfile(data);
        setEditedProfile(data);
        setPhotoURL(data.photoURL);
      });
    }
  }, []);

  const handleInputChange = (field, value) => {
    setEditedProfile(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handlePhotoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert("No file selected.");
      return;
    }
  
    const validTypes = ['image/jpeg', 'image/png', 'image/gif'];
    if (!validTypes.includes(file.type)) {
      alert("Please upload an image in JPG, PNG, or GIF format.");
      return;
    }
  
    if (file.size > 5 * 1024 * 1024) {
      alert("The image must be less than 5MB.");
      return;
    }
  
    try {
      setUploading(true);
      const userId = auth.currentUser?.uid;
      if (!userId) throw new Error("User not authenticated.");
  
      // Reference to the storage location
      const imageRef = storageRef(storage, `profile-photos/${userId}`);
      
      // Upload the file
      const snapshot = await uploadBytes(imageRef, file);
      console.log("File uploaded successfully:", snapshot);
  
      // Get the download URL
      const downloadURL = await getDownloadURL(imageRef);
      console.log("Download URL:", downloadURL);
  
      // Update the user's profile in the database
      const userRef = ref(database, `users/${userId}`);
      await update(userRef, {
        ...editedProfile,
        photoURL: downloadURL,
      });
  
      // Update the state
      setPhotoURL(downloadURL);
      setEditedProfile((prev) => ({ ...prev, photoURL: downloadURL }));
    } catch (error) {
      console.error("Error uploading photo:", error);
      alert("Error during upload. Please try again later.");
    } finally {
      setUploading(false);
    }
  };
  

  const handleSave = async () => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      try {
        const userRef = ref(database, `users/${userId}`);
        await update(userRef, editedProfile);
        setIsEditing(false);
        setProfile(editedProfile);
      } catch (error) {
        console.error('Error saving profile:', error);
        alert('Errore durante il salvataggio del profilo. Riprova più tardi.');
      }
    }
  };

  if (!profile) {
    return (
      <div className="flex min-h-screen bg-white">
        <Sidebar />
        <div className="flex-1 flex items-center justify-center">
          <div className="text-center text-lg text-gray-600">
            <div className="w-24 h-24 mx-auto mb-4">
              <img src="/loading.svg" alt="Loading" className="w-full h-full" />
            </div>
            Caricamento...
          </div>
        </div>
      </div>
    );
  }

  const renderField = (label, field, type = 'text', placeholder = '', hint = '') => {
    if (isEditing) {
      return (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2 ">
            {label}
            {hint && <span className="text-gray-500 text-xs"> ({hint})</span>}
          </label>
          {type === 'textarea' ? (
            <textarea
              value={editedProfile[field] || ''}
              onChange={(e) => handleInputChange(field, e.target.value)}
              placeholder={placeholder}
              className="w-full p-2 border border-gray-200 rounded-lg min-h-[100px]"
            />
          ) : (
            <input
              type={type}
              value={editedProfile[field] || ''}
              onChange={(e) => handleInputChange(field, e.target.value)}
              placeholder={placeholder}
              className="w-full p-2 border border-gray-200 rounded-lg"
            />
          )}
        </div>
      );
    }
    return (
      <p className="text-gray-600">
        <span className="font-medium">{label}:</span> {profile[field] || ''}
      </p>
    );
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 p-4 sm:p-8 ml-52">
        <div className="max-w-4xl mx-auto">
          <header className="flex justify-between items-center mb-8">
            <h1 className="text-3xl font-serif text-emerald-800 font-semibold">Il Tuo Profilo</h1>
            {!isEditing ? (
              <button 
                onClick={() => setIsEditing(true)}
                className="text-coral-500 hover:text-coral-600 flex items-center gap-2"
              >
                <Edit2 className="w-5 h-5" />
                Modifica profilo
              </button>
            ) : (
              <button 
                onClick={handleSave}
                className="text-coral-500 hover:text-coral-600 flex items-center gap-2"
              >
                <Save className="w-5 h-5" />
                Salva modifiche
              </button>
            )}
          </header>

          <div className="bg-white rounded-lg shadow-sm p-6 mb-6">
            <div className="flex items-center gap-6 mb-8 flex-col sm:flex-row">
              <div className="relative">
                <div className="w-24 h-24 bg-gray-100 rounded-full overflow-hidden">
                  {photoURL ? (
                    <img 
                      src={photoURL} 
                      alt="Profile" 
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center">
                      <User className="w-12 h-12 text-gray-400" />
                    </div>
                  )}
                </div>
                {isEditing && (
                  <div className="absolute bottom-0 right-0">
                    <label className="cursor-pointer">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handlePhotoUpload}
                        className="hidden"
                        disabled={uploading}
                      />
                      <div className="bg-white rounded-full p-2 shadow-md hover:bg-gray-50">
                        <Upload className="w-4 h-4 text-gray-600" />
                      </div>
                    </label>
                  </div>
                )}
              </div>
              <div className="text-center sm:text-left mt-4 sm:mt-0 ">
                <h2 className="text-xl font-serif text-gray-800">{profile.name} {profile.surname}</h2>
                <p className="text-gray-600">{profile.email}</p>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 " >
              {renderField('Nome', 'name', 'text', 'Il tuo nome', 'Inserisci il tuo primo nome')}
              {renderField('Cognome', 'surname', 'text', 'Il tuo cognome', 'Inserisci il tuo cognome')}
              {renderField('Email', 'email', 'email', 'La tua email')}
              {renderField('Approccio', 'approach', 'text', 'Il tuo approccio professionale', 'specificare l’orientamento, es. psicodinamico, psicoanalitico, cognitivo-comportamentale…')}
              {renderField('Dove vive', 'location', 'text', 'La tua città', 'indicare lo stato e la città')}
              {renderField('Di cosa si occupa', 'areas', 'text', 'La tua area professionale', 'Indica la tua area professionale')}
              {renderField('Curriculum', 'curriculum', 'textarea', 'ESEMPIO: Dopo la laurea in psicologia curriculum …, ho frequentato un corso di perfezionamento … e successivamente mi sono specializzata in psicoterapia con …. Ho approfondito …. Ho lavorato…lavoro presso…nel mio lavoro mi occupo di ….”')}
              {renderField('Registrazione all\'Albo', 'registration', 'text', 'Numero di registrazione', 'specificare quale albo e la data di iscrizione')}
              {renderField('Laurea', 'degree', 'text', 'Il tuo titolo di studio', 'specificare il titolo di laurea e l’Università')}
              {renderField('Specializzazione', 'specialization', 'text', 'La tua specializzazione', 'specificare il nome della specializzazione e la scuola presso la quale è stata conseguita')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tprofile;