import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import { User } from 'lucide-react';

const TherapistDetailsModal = ({ therapist, open, onOpenChange }) => {
  if (!therapist) return null;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-2xl max-h-[90vh] overflow-y-auto bg-white">
        <DialogHeader>
          <DialogTitle className="text-xl font-medium text-emerald-800">
            Dettagli del terapeuta
          </DialogTitle>
        </DialogHeader>

        <div className="space-y-6 py-4">
          <div className="flex items-center gap-6 mb-8">
            <div className="w-24 h-24 bg-gray-100 rounded-full flex items-center justify-center">
              <User className="w-12 h-12 text-gray-400" />
            </div>
            <div className=' gap-1'>
            <div>
              <p className="text-sm text-gray-600 mb-2"><p className='font-semibold'>Nome e cognome:</p> {therapist.name} {therapist.surname}</p>
            </div>
            <div>
              <p className="text-sm text-gray-600 "><p className='font-semibold'>Dove vive:</p> {therapist.location}</p>
            </div>
            </div>
          </div>

          {therapist.areas && (
            <section>
              <h3 className="text-lg font-medium mb-3 text-emerald-800 font-semibold">Di cosa si occupa</h3>
              <div className="flex items-start gap-3">
                <div className="w-5 h-5 mt-0.5">
                  <div className="w-5 h-5 border-2 border-emerald-600 rounded flex items-center justify-center">
                    <div className="w-3 h-3  rounded-sm" />
                  </div>
                </div>
                <p className="text-sm text-gray-700 flex-1">{therapist.areas}</p>
              </div>
            </section>
          )}

          {therapist.approach && (
            <section>
              <h3 className="text-lg font-medium mb-3 text-emerald-800 font-semibold">Il metodo</h3>
              <p className="text-sm text-gray-700 leading-relaxed">{therapist.approach}</p>
            </section>
          )}

          <section>
            <h3 className="text-lg font-medium mb-3 text-emerald-800 font-semibold">Dal suo curriculum</h3>
            <div className="space-y-4">
              {therapist.registration && (
                <div className="flex items-start gap-3">
                  <div className="w-5 h-5 mt-0.5">
                    <div className="w-5 h-5 border-2 border-emerald-600 rounded" />
                  </div>
                  <div className="flex-1 ">
                    <p className="text-sm font-medium  text-gray-700 font-semibold">Registrazione all'albo</p>
                    <p className="text-sm text-gray-600">{therapist.registration}</p>
                  </div>
                </div>
              )}
              
              {therapist.degree && (
                <div className="flex items-start gap-3">
                  <div className="w-5 h-5 mt-0.5">
                    <div className="w-5 h-5 border-2 border-emerald-600 rounded" />
                  </div>
                  <div className="flex-1">
                    <p className="text-sm font-medium text-gray-700 font-semibold">Laurea</p>
                    <p className="text-sm text-gray-600">{therapist.degree}</p>
                  </div>
                </div>
              )}

              {therapist.curriculum && (
                <div className="flex items-start gap-3">
                  <div className="w-5 h-5 mt-0.5">
                    <div className="w-5 h-5 border-2 border-emerald-600 rounded" />
                  </div>
                  <div className="flex-1">
                    <p className="text-sm font-medium text-gray-700 font-semibold">Esperienza professionale</p>
                    <p className="text-sm text-gray-600">{therapist.curriculum}</p>
                  </div>
                </div>
              )}
            </div>
          </section>

          {therapist.description && (
            <section>
              <h3 className="text-lg font-medium mb-3 text-emerald-800 font-semibold">Come si descrive</h3>
              <p className="text-sm text-gray-700 leading-relaxed">{therapist.description}</p>
            </section>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TherapistDetailsModal;