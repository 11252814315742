import React, { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { auth, database } from '../../firebaseConfig';
import { FileText } from 'lucide-react';
import Sidebar from './Sidebar';

const InvoicesPage = () => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const userId = auth.currentUser?.uid;
    if (userId) {
      const invoicesRef = ref(database, `invoices/${userId}`);
      onValue(invoicesRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const invoicesArray = Object.entries(data).map(([id, invoiceData]) => ({
            id,
            ...invoiceData,
          }));
          setInvoices(invoicesArray);
        }
      });
    }
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'Data non disponibile';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Data non valida';
    return new Intl.DateTimeFormat('it-IT').format(date);
  };

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
    }).format(amount);
  };

  return (
    <div className="flex flex-col md:flex-row min-h-screen bg-white">
      {/* Sidebar */}
      <Sidebar />

      {/* Main Content */}
      <div className="flex-1 p-4 bg-gray-50 md:p-8 ml-0 lg:ml-52">
        <div className="max-w-4xl mx-auto">
          <header className="flex flex-col md:flex-row justify-between items-end md:items-center mb-8">
            <h1 className="text-2xl  md:text-3xl font-serif text-emerald-800 font-semibold">
              Fatture
            </h1>
          </header>

          {/* Responsive Table */}
          <div className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full text-sm">
                <thead className="border-b bg-gradient-to-r from-emerald-400/80 to-teal-400/80 text-white rounded-t-lg">
                  <tr>
                    <th className="px-4 md:px-6 py-3 text-left font-medium">Terapeuta</th>
                    {/* <th className="px-4 md:px-6 py-3 text-left font-medium">Numero</th> */}
                    <th className="px-4 md:px-6 py-3 text-left font-medium">Data</th>
                    <th className="px-4 md:px-6 py-3 text-left font-medium">Importo</th>
                    <th className="px-4 md:px-6 py-3 text-left font-medium">Stato</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {invoices.length > 0 ? (
                    invoices.map((invoice, index) => (
                      <tr key={invoice.id || index} className="hover:bg-gray-50">
                        <td className="px-4 md:px-6 py-4 text-gray-900">{`${invoice.therapistName} ${invoice.therapistSurname}`}</td>
                        {/* <td className="px-4 md:px-6 py-4 text-gray-900">{invoice.id}</td> */}
                        <td className="px-4 md:px-6 py-4 text-gray-600">{formatDate(invoice.date)}</td>
                        <td className="px-4 md:px-6 py-4 text-gray-900">{formatAmount(invoice.amount)}</td>
                        <td className="px-4 md:px-6 py-4">
                          <span
                            className={`px-2 py-1 rounded ${
                              invoice.status === 'paid'
                                ? 'bg-green-100 text-green-600'
                                : 'bg-yellow-100 text-yellow-600'
                            }`}
                          >
                            {invoice.status}
                          </span>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="px-6 py-8 text-center text-gray-500">
                        <FileText className="w-12 h-12 mx-auto mb-4 text-gray-400" />
                        Nessuna fattura disponibile al momento
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicesPage;
